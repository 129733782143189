// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

html {
  font-family: 'Quicksand', sans-serif;
  width: 100%;
  height: 100%;
}

body{
  height: -webkit-fill-available;
}

.my-custom-container {
  border-radius: 100% !important;
}

input,
button,
select,
option {
  font-family: 'Quicksand', sans-serif;
}

app-root {
  display: block;
  height: 100%;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

* {
  box-sizing: border-box;
  font-family: Quicksand;
}

mat-dialog-container {
  background-color: #FFFFFF !important;
}

.mat-tab-label{
  font-weight: bold !important;
}


.mat-tab-label-active{
  background-color: #33cbcf !important;
  color: white !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  visibility: hidden !important;
}

.buttons {
  .mat-grid-tile-content {
    display: flex !important;
    justify-content: flex-start !important;
  }
}

.download {
  .mat-grid-tile-content {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.mat-stroked-button {
  font-family: 'Quicksand' sans-serif !important;
}

.dashboard-mat-grid {

  .mat-grid-tile-content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: stretch !important;
    justify-content: space-evenly !important;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-content: stretch !important;
    flex-wrap: wrap;
  }

}

.configuration-mat-grid {

  .mat-grid-tile-content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: stretch !important;
    justify-content: space-evenly !important;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-content: stretch !important;
    flex-wrap: wrap;
  }

}



.historical-mat-grid {

  .mat-grid-tile-content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
  }

}

mat-stroked-button {
  font-family: 'Quicksand', sans-serif;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}
